const NextI18Next = require('next-i18next').default;

module.exports = new NextI18Next({
  shallowRender: true,
  defaultLanguage: 'en',
  otherLanguages: ['es', 'fr', 'it'],
  localePath: 'public/locales',
  keySeparator: false,
  ns: ['common', 'swal', 'error'],
});
